// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Subnames-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--Borders-01, #2d2d2d);
  padding: 8px 24px 8px 8px;
  margin-bottom: 10px;
  border-radius: 25px;
  width: auto;
}

.subnames-left {
  display: flex;
  align-items: center;
  margin-right: 10px;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
  background: var(--Card-fills-01, #121212);
  overflow: hidden;
}

.subnames-right {
  margin-right: 0px;
  display: flex;
}

.icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.Subnames-text {
  flex-grow: 1;
  color: white;
}

.Subnames-heading {
  color: var(--fonts-icons-01, #fffff5);
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .Subnames-heading {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/subnames/Subnames.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,4CAA4C;EAC5C,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".Subnames-container {\n  display: flex;\n  align-items: center;\n  border: 1px solid var(--Borders-01, #2d2d2d);\n  padding: 8px 24px 8px 8px;\n  margin-bottom: 10px;\n  border-radius: 25px;\n  width: auto;\n}\n\n.subnames-left {\n  display: flex;\n  align-items: center;\n  margin-right: 10px;\n  display: flex;\n  width: 40px;\n  height: 40px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  object-fit: cover;\n  background: var(--Card-fills-01, #121212);\n  overflow: hidden;\n}\n\n.subnames-right {\n  margin-right: 0px;\n  display: flex;\n}\n\n.icon {\n  margin-right: 5px;\n  display: flex;\n  align-items: center;\n}\n\n.Subnames-text {\n  flex-grow: 1;\n  color: white;\n}\n\n.Subnames-heading {\n  color: var(--fonts-icons-01, #fffff5);\n  margin-top: 32px;\n  margin-bottom: 24px;\n  font-size: 24px;\n}\n\n@media screen and (max-width: 600px) {\n  .Subnames-heading {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
