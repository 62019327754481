// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.owner-container {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 10px;
  border-radius: 25px;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
  background-color: #3d3d3d;
}

.owner-main-container {
  display: flex;
  flex-wrap: wrap;
}

.owner-text {
  padding: 0px 10px;
  color: white;
  font-size: 20px;
}

.owner-heading {
  color: var(--fonts-icons-01, #fffff5);
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .owner-heading {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ownership/Ownership.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".owner-container {\n  display: flex;\n  align-items: center;\n  padding: 8px 16px;\n  margin-bottom: 10px;\n  border-radius: 25px;\n  width: fit-content;\n  margin-right: 10px;\n  background-color: #3d3d3d;\n}\n\n.owner-main-container {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.owner-text {\n  padding: 0px 10px;\n  color: white;\n  font-size: 20px;\n}\n\n.owner-heading {\n  color: var(--fonts-icons-01, #fffff5);\n  margin-top: 24px;\n  margin-bottom: 24px;\n  font-size: 24px;\n}\n\n@media screen and (max-width: 600px) {\n  .owner-heading {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
