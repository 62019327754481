import React from 'react'
import Provider from '../../components/Provider/Provider'

const ProviderPage = () => {
  return (
    <div>
        <Provider/>
    </div>
  )
}

export default ProviderPage