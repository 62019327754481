// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--Borders-01, #2d2d2d);
  padding: 8px 24px 8px 8px;
  border-radius: 25px;
  max-width: 212px;
  width: auto;
  padding: 8px 24px 8px 8px;
  margin-right: 8px;
}

.address-left {
  display: flex;
  align-items: center;
  margin-right: 10px;
  display: flex;
  width: 65px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
  background: var(--Card-fills-01, #121212);
  overflow: hidden;
}

.address-right {
  margin-left: auto;
  display: flex;
}

.icon {
  margin-right: 5px;
}

.address-text {
  color: white;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Addresses-heading {
  color: var(--fonts-icons-01, #fffff5);
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .Addresses-heading {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/addresses/Addresses.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,4CAA4C;EAC5C,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".address-container {\n  display: flex;\n  align-items: center;\n  border: 1px solid var(--Borders-01, #2d2d2d);\n  padding: 8px 24px 8px 8px;\n  border-radius: 25px;\n  max-width: 212px;\n  width: auto;\n  padding: 8px 24px 8px 8px;\n  margin-right: 8px;\n}\n\n.address-left {\n  display: flex;\n  align-items: center;\n  margin-right: 10px;\n  display: flex;\n  width: 65px;\n  height: 40px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  object-fit: cover;\n  background: var(--Card-fills-01, #121212);\n  overflow: hidden;\n}\n\n.address-right {\n  margin-left: auto;\n  display: flex;\n}\n\n.icon {\n  margin-right: 5px;\n}\n\n.address-text {\n  color: white;\n  flex-grow: 1;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.Addresses-heading {\n  color: var(--fonts-icons-01, #fffff5);\n  margin-top: 32px;\n  margin-bottom: 24px;\n  font-size: 24px;\n}\n\n@media screen and (max-width: 600px) {\n  .Addresses-heading {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
