// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .App {
  background-color: #1B1B17;
  height: fit-content;
  display: flex;
  font-size: 20px;
}

.leftcontainer {
  display: flex;
  width: 40%;
  height: 100vh;

}

.rightcontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 20px;
}

  @media screen and (max-width: 900px) {
    .App {
      background-color: #1B1B17;
      height: fit-content;
      display: flex;
      font-size: 20px;

      flex-wrap: wrap;
    }

    .leftcontainer {
      display: flex;
      width: 100%;
      height: 675px;
    
    }
  }

  @media screen and (max-width: 600px) {

    .App {
      font-size: 14px;
    }
    .leftcontainer {
      display: flex;
      width: 100%;
      height: 500px;
    
    }

  } */

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KAoDK","sourcesContent":["/* .App {\n  background-color: #1B1B17;\n  height: fit-content;\n  display: flex;\n  font-size: 20px;\n}\n\n.leftcontainer {\n  display: flex;\n  width: 40%;\n  height: 100vh;\n\n}\n\n.rightcontainer {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  width: 60%;\n  padding: 20px;\n}\n\n  @media screen and (max-width: 900px) {\n    .App {\n      background-color: #1B1B17;\n      height: fit-content;\n      display: flex;\n      font-size: 20px;\n\n      flex-wrap: wrap;\n    }\n\n    .leftcontainer {\n      display: flex;\n      width: 100%;\n      height: 675px;\n    \n    }\n  }\n\n  @media screen and (max-width: 600px) {\n\n    .App {\n      font-size: 14px;\n    }\n    .leftcontainer {\n      display: flex;\n      width: 100%;\n      height: 500px;\n    \n    }\n\n  } */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
