import Loginpageimage from "./Login-page-image.png";
import musterIcon from "./musterIcon.svg";
import filledCheckBox from "./filledCheckBox.svg";
import outlinedCheckBox from "./outlinedCheckBox.svg";
import tlon from "./tlon.svg";
import holium from "./holium.svg";
import backIcon from "./backIcon.svg";
import wallet from "./wallet.svg";
import openEye from "./openEye.svg";
import closeEye from "./closeEye.svg";
import modalWarning from "./modalWarning.svg";
import copy from "./copy.svg";
// import icon! from "./icon!.svg"
import exclamatoryIcon from "./exclamatoryIcon.svg";
import loginSideDesign from "./loginSideDesign.jpeg";
import loginRightSideDesign from "./loginRightSideDesign.svg";
import siteIcon from "./siteIcon.svg";
import editPencile from "./editPencile.svg";
import muterText from "./musterText.svg";
import messageIcon from "./messageIcon.svg";
import sigilGenerator from "./sigilGenerator.png";
import facebookIcon from "./facebookIcon.svg";
import urbitforkGroup from "./urbitforkGroup.png";
import facebookIndianFork from "./facebookIndianFork.svg";
import expandIcon from "./expandIcon.svg";
import urbitIndianForkbottomIcon from "./urbitIndianForkbottomIcon.svg";
import musterOnbordingImg from "./musterOnbordingImg.png";
import imagebottomstyle from "./imagebottomstyle.svg";
import sidebarSelectedIcon from "./sidebarSelectedIcon.svg";
import sidebarUncheckedBox from "./sidebarUncheckedBox.svg";
import CheckedCreatedIcon from "./CheckedCreatedIcon.svg";

const assetsImage = {
  Loginpageimage,
  musterIcon,
  filledCheckBox,
  outlinedCheckBox,
  tlon,
  holium,
  backIcon,
  wallet,
  openEye,
  closeEye,
  modalWarning,
  copy,
  exclamatoryIcon,
  loginSideDesign,
  loginRightSideDesign,
  siteIcon,
  editPencile,
  muterText,
  messageIcon,
  sigilGenerator,
  facebookIcon,
  urbitforkGroup,
  facebookIndianFork,
  expandIcon,
  urbitIndianForkbottomIcon,
  musterOnbordingImg,
  imagebottomstyle,
  sidebarSelectedIcon,
  sidebarUncheckedBox,
  CheckedCreatedIcon,
};
export default assetsImage;
