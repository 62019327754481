import React from 'react'
import SignupUrbitID from '../../components/signupUrbitID/SignupUrbitID'

const SignUrbit = () => {
  return (
    <div>
        <SignupUrbitID/>
    </div>
  )
}

export default SignUrbit