// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Urbit-Domain-container {
  background-color: #1b1b17;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  font-size: 20px;
}

.urbitDomainleftcontainer {
  display: flex;
  width: 40%;
  height: 100vh;
}

.urbitDomainRightcontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 15px;
}

@media screen and (max-width: 900px) {
  .Urbit-Domain-container {
    background-color: #1b1b17;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    font-size: 20px;

    flex-wrap: wrap;
  }

  .urbitDomainleftcontainer {
    display: flex;
    width: 100%;
    height: 675px;
  }
}

@media screen and (max-width: 600px) {
  .Urbit-Domain-container {
    font-size: 14px;
  }
  .urbitDomainleftcontainer {
    display: flex;
    width: 100%;
    height: 500px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/UrbitDomain/UrbitDomain.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,aAAa;AACf;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,aAAa;AACf;;AAEA;EACE;IACE,yBAAyB;IACzB,wBAAmB;IAAnB,mBAAmB;IACnB,aAAa;IACb,eAAe;;IAEf,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,aAAa;EACf;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,aAAa;IACb,WAAW;IACX,aAAa;EACf;AACF","sourcesContent":[".Urbit-Domain-container {\n  background-color: #1b1b17;\n  height: fit-content;\n  display: flex;\n  font-size: 20px;\n}\n\n.urbitDomainleftcontainer {\n  display: flex;\n  width: 40%;\n  height: 100vh;\n}\n\n.urbitDomainRightcontainer {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  width: 60%;\n  padding: 15px;\n}\n\n@media screen and (max-width: 900px) {\n  .Urbit-Domain-container {\n    background-color: #1b1b17;\n    height: fit-content;\n    display: flex;\n    font-size: 20px;\n\n    flex-wrap: wrap;\n  }\n\n  .urbitDomainleftcontainer {\n    display: flex;\n    width: 100%;\n    height: 675px;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .Urbit-Domain-container {\n    font-size: 14px;\n  }\n  .urbitDomainleftcontainer {\n    display: flex;\n    width: 100%;\n    height: 500px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
