// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Poaps-container {
  display: flex;
  flex-wrap: wrap;
}

.Poaps-image-container {
  margin-right: 15px;
  text-align: center;
}

.Poaps-rounded-image {
  border-radius: 50%;
  width: 141px;
  height: 141px;
  object-fit: cover;
}

.Poaps-heading {
  color: var(--fonts-icons-01, #fffff5);
  /* margin-top: 32px;
  margin-bottom: 24px; */
  font-size: 24px;
}
.Poaps-svg-background {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Button-Primary, #f4f85a);
  /* margin-top: 20px; */
}

.poaps-right {
  margin-left: auto;
}

@media screen and (max-width: 600px) {
  .Poaps-rounded-image {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    object-fit: cover;
  }
  .Poaps-heading {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Poaps/Poaps.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,qCAAqC;EACrC;wBACsB;EACtB,eAAe;AACjB;AACA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gDAAgD;EAChD,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".Poaps-container {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.Poaps-image-container {\n  margin-right: 15px;\n  text-align: center;\n}\n\n.Poaps-rounded-image {\n  border-radius: 50%;\n  width: 141px;\n  height: 141px;\n  object-fit: cover;\n}\n\n.Poaps-heading {\n  color: var(--fonts-icons-01, #fffff5);\n  /* margin-top: 32px;\n  margin-bottom: 24px; */\n  font-size: 24px;\n}\n.Poaps-svg-background {\n  display: flex;\n  width: 40px;\n  height: 40px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 8px;\n  border: 1px solid var(--Button-Primary, #f4f85a);\n  /* margin-top: 20px; */\n}\n\n.poaps-right {\n  margin-left: auto;\n}\n\n@media screen and (max-width: 600px) {\n  .Poaps-rounded-image {\n    border-radius: 50%;\n    width: 56px;\n    height: 56px;\n    object-fit: cover;\n  }\n  .Poaps-heading {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
