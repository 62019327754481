import React from 'react'
import SignupEmail from '../../components/SignupEmail/SignupEmail'

const SignupEmailPage = () => {
  return (
    <div>
        <SignupEmail/>
    </div>
  )
}

export default SignupEmailPage